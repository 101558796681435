<template>
  <div id="app" :class="{ bgNone: !bgState }">
    <CutInternet ref="CutInternet"></CutInternet>
    <pageHeader @show="showPicker = true"></pageHeader>
    <!-- <ContentWallet></ContentWallet> -->

    <router-view />

    <!-- <BottomNav></BottomNav> -->


    <LoadingModule></LoadingModule>

    <van-popup v-model:show="showPicker" round position="bottom">
      <van-picker confirm-button-text="√" cancel-button-text="×" class="o-class" show-toolbar :columns="columns"
        @cancel="showPicker = false" @confirm="onConfirm" />
    </van-popup>
  </div>
</template>

<script>
import CutInternet from '@/components/CutInternet.vue'
import pageHeader from '@/components/Header.vue'
import { GetWalletAddress, CutUserInternet, CutInternetAndGetUserAddress } from "@/views/MINT/js/contractMethods.js";
// import ContentWallet from "./components/ContentWallet.vue";
import BottomNav from "./components/BottomNav.vue";
import LoadingModule from "./components/LoadingModule.vue";
export default {
  name: "App",
  components: {
    CutInternet,
    pageHeader,
    // ContentWallet,
    BottomNav,
    LoadingModule,
  },
  data() {
    return {
      show: false,
      showPicker: false,
      columns: [
        { text: '简体', value: 'zh' },
        // { text: '繁体', value: 'zh-ft' },
        { text: 'ENGLISH', value: 'en' },
      ],
    }
  },
  computed: {
    wallet() {
      return this.$store.state.wallet;
    },
    bgState() {
      return this.$route.path !== "/" && this.$route.path !== "/My";
    },
    bindState() {
      return this.$store.state.bindState;
    },
    bindWindowState() {
      return this.$store.state.bindWindowState;
    },
  },
  watch: {
    bindState(val) {
      if (val) {
        this.$global.testBind(this.$route.query.shareurl);
        this.$store.commit("SetBindWindowState", false);
      }
    },
  },
  created() {
    // this.$global.testBind();
  },
  async mounted() {
    // if (this.wallet) {
    CutInternetAndGetUserAddress()
    // const address = await GetWalletAddress();
    // this.$store.commit("SetWalletAddress", address);
    // }
    this.fn();
    setTimeout(() => {
      this.$refs.CutInternet.close()
    }, 1500)
  },

  methods: {
    onConfirm(e) {
      this.$i18n.locale = e.value;
      this.$store.commit('SET_language', e.value)
      this.showPicker = false
    },
    fn() {
      ethereum.on("accountsChanged", () => {
        window.location.reload();
      });
      //监听链网络改变
      ethereum.on("chainChanged", () => {
        console.log("链切换");
        window.location.reload();
      });
    },
  },
};
</script>

<style lang="scss">
@import "./assets/css/base.scss";

#app {
  width: 100vw;
  min-height: 100vh;
  // background: url(~@/assets/img/1.jpg) no-repeat;
  background-size: 100% 100%;

  // background-color: #ebb0b3;
  &.bgNone {
    // background-image: none;
  }
}

.o-class {
  &.van-picker {
    background-color: #000;
  }

  .van-picker__mask {}
}
</style>


<style>
.o-class .van-picker__mask {
  background-image: none !important;
}

.o-class .van-picker-column__item--selected {
  color: #02FAFC !important;
}
</style>